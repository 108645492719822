import "./polyfills/polyfills";

import { setOptions } from "./options/options";
import { collectDeviceProfile } from "./lib/fraud/fraud";
import { UtilsApi } from "./lib/types/types";

// Modify window interface to allow the SheerID object.
declare global {
  interface Window {
    sheerIdUtils: UtilsApi;
  }
}

declare let __webpack_public_path__: string; // eslint-disable-line

const windowInterface: UtilsApi = {
  setOptions,
  determineDevice: collectDeviceProfile,
};

window.sheerIdUtils = windowInterface;

// NOTE: The following lines will make sure the different chunks (e.g. languages) are loaded from
// the same origin as the main.js file. This is only valid and necessary for the "es5" version of the library
// @ts-ignore
const scriptBasePath = document.currentScript.src.substr(
  0,
  // @ts-ignore
  document.currentScript.src.lastIndexOf("/"),
);

__webpack_public_path__ = `${scriptBasePath}/${__webpack_public_path__}`; // eslint-disable-line
